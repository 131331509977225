import React from 'react';

class Expedia extends React.Component {
    render() {
        return (
            <div style={{
                display: 'flex',
                flexFlow: 'column',
                borderBottom: '1px solid #ccc',
            }}>
                <h4>Overview</h4>
                <p>
                    Working with the Lodging Data Insights team as an Software Engineer I am responsible for
                    writing APIs to expose data needed for the Expedia Partner Central(EPC) web application. I also
                    build various internal tools.
                </p>
                <h4>Notable Achievements</h4>
                <ul>
                    <li>
                        Built and deployed an API service to store the user's interaction with Discovery Cards
                    </li>
                    <ul>
                        <li>Node.js</li>
                        <li>Express</li>
                        <li>MongoDB</li>
                    </ul>
                    <li>
                        Found and fixed a memory leak in legacy code discovered while scaling out
                    </li>
                    <ul>
                        <li>Node.js Inspector</li>
                        <li>Chrome DevTools - Memory</li>
                    </ul>
                    <li>Built dashboard to display metrics associated with user's interactions with Discovery Cards</li>
                    <ul>
                        <li>React</li>
                        <li>Redux</li>
                        <li>Graph.js</li>
                        <li>react-table</li>
                        <li>MongoDB</li>
                    </ul>
                    <li>Exposed MongoDB and Redis data with GraphQL for a Day Away project</li>
                    <ul>
                        <li>GraphQL</li>
                        <li>Redis</li>
                        <li>MongoDB</li>
                    </ul>
                </ul>
            </div>
        );
    }
}

export default Expedia;