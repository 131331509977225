import React from 'react';
class iLink extends React.Component {
    render() {
        return (
            <div style={{
                display: "flex",
                flexFlow: "column",
                borderBottom: "1px solid #ccc"
            }}>
                <h4>Overview</h4>
                <p>Working with a large team of developers creating web applications and completing contracts with other
                    companies</p>
                <h4>Notable Achievements</h4>
                <ul>
                    <li>Built a web app to display inventory and sales data for a car dealership</li>
                    <ul>
                        <li>jQT (formerly jQTouch)</li>
                        <li>.NET</li>
                        <li>REST API</li>
                    </ul>
                    <li>Provided support to coworkers working on similar projects</li>
                    <li>Completed a 2 year contract with DIRECTV</li>
                </ul>
            </div>
        )
    }
}

export default iLink;