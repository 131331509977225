import React from 'react';
class DIRECTV extends React.Component {
    render() {
        return (
            <div style={{
                display: "flex",
                flexFlow: "column",
                borderBottom: "1px solid #ccc"
            }}>
                <h4>Overview</h4>
                <p>
                    Working with the IT Enterprise and Innovation team building web application prototypes for many
                    projects using various browsers including mobile, tablet, TV, and legacy.
                </p>
                <h4>Notable Achievements</h4>
                <ul>
                    <li>
                        Built a mobile web application that the technicians use to receive information about their
                        installation appointments
                    </li>
                    <ul>
                        <li>
                            Shadowed DIRECTV installers to their appointment to gather feedback
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Sencha Touch
                        </li>
                        <li>
                            Phonegap
                        </li>
                        <li>
                            Google Maps JavaScript API
                        </li>
                    </ul>
                    <li>
                        Built a web app that serves scripts to customer care agents to down-sell, up-sell
                        and cross-sell DIRECTV packages
                    </li>
                    <ul>
                        <li>
                            Ext JS
                        </li>
                        <li>
                            Sencha Touch
                        </li>
                    </ul>
                    <li>
                        Built a Samsung Smart TV data driven app that scrolls through movies, views trailer and other
                        movie info
                    </li>
                    <ul>
                        <li>
                            Samsung Smart TV SDK
                        </li>
                    </ul>
                    <li>
                        Tested enterprise customer service solutions and integrated in-house customer service web apps
                    </li>
                    <ul>
                        <li>Jacada</li>
                        <li>Salesforce</li>
                    </ul>
                    <li>
                        Provided coding support for other teams and new hires
                    </li>
                </ul>
            </div>
        )
    }
}

export default DIRECTV;