import React from 'react';
class Contact extends React.Component {
    trackOutbound(event) {
        window.ga('send', 'event', 'Contact', 'Link', 'OutboundLink', event.target.href);
    }
    trackEmail(event) {
        window.ga('send', 'event', 'Contact', 'Email', event.target.href);
    }
    render() {
        return (
            <article id="contact">
                <h1>Contact</h1>
                <p><a
                    onClick={this.trackEmail.bind(this)}
                    href="mailto:bensisson1@gmail.com">Email</a></p>
                <p><a
                    onClick={this.trackOutbound.bind(this)}
                    href="https://www.linkedin.com/in/ben-sisson-9322142a/">LinkedIn</a></p>
            </article>
        );
    }
}

export default Contact;