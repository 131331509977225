import React from 'react';

class Overview extends React.Component {
    render() {
        const firstProJob = new Date('10/13/2010');
        const now = new Date();
        const proLengthMS = now.getTime() - firstProJob.getTime();
        const yearsPro = Math.floor(proLengthMS / 3.154e+10);
        return (
            <article id="overview" className="overview">
                <p>
                    I am a Software Engineer at Expedia working with the Lodging Data Insights team.
                    I have more than {yearsPro} years of professional experience with Web Application Development&mdash;Feel
                    free to <a href="mailto:bensisson1@gmail.com">contact me</a>. Thanks!
                </p>
                <div className="light-grey-rectangle"/>
            </article>
        );
    }
}

export default Overview;