import React from 'react';

class Education extends React.Component {
    trackOutbound(event) {
        window.ga('send', 'event', 'Education', 'Link', 'OutboundLink', event.target.href);
    }

    render() {
        return (
            <article id="education">
                <h1>Education</h1>
                <p><span>Central Washington University</span> &middot; B.S. Computer Science &middot; 2010 &middot; <a
                    onClick={this.trackOutbound.bind(this)}
                    target="_blank"
                    href="http://cwu.edu">Read More</a></p>
                <p><span>Global Information Assurance Certification</span> &middot; Certified Web Application
                    Defender &middot; 2014 &middot; <a
                        onClick={this.trackOutbound.bind(this)}
                        target="_blank"
                        href="https://www.giac.org/certification/certified-web-application-defender-gweb">Read More</a>
                </p>
            </article>
        );
    }
}

export default Education;