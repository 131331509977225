import React from 'react';
import Disney from '../Experience/Disney/Disney';
import Expedia from '../Experience/Expedia/Expedia';
import DIRECTV from '../Experience/DIRECTV/DIRECTV';
import ILink from '../Experience/iLink/iLink';
import SmoothScroll from 'smooth-scroll';
const smoothScroll = new SmoothScroll();

class Experience extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayDisney: false,
            displayDIRECTV: false,
            displayILinkSystems: false,
        };
    }

    render() {
        let elementsDataArray = [{
            jobTitle: 'Software Engineer',
            location: 'Bellevue, WA',
            companyName: 'Expedia',
            dateSpan: 'August 2017-Present',
            element: <Expedia/>,
            stateKey: 'displayExpedia',
        }, {
            jobTitle: 'Analytics Engineer',
            location: 'Glendale, CA then Seattle, WA',
            companyName: 'Disney',
            dateSpan: 'May 2013-Dec 2016',
            element: <Disney/>,
            stateKey: 'displayDisney',
        }, {
            jobTitle: 'Software Engineer',
            location: 'El Segundo, CA',
            companyName: 'DIRECTV',
            dateSpan: 'May 2011-May 2013',
            element: <DIRECTV/>,
            stateKey: 'displayDIRECTV',
        }, {
            jobTitle: 'Software Engineer',
            location: 'Bellevue, WA',
            companyName: 'iLink Systems',
            dateSpan: 'Oct 2010-May 2013',
            element: <ILink/>,
            stateKey: 'displayILinkSystems',
        }];

        let elementsDisplay = elementsDataArray.map((element) => {
            return [<p style={this.state[element.stateKey] ? {
                borderBottom: 'solid 1px #000',
                paddingBottom: '10px',
            } : {}}>
                <span>{element.jobTitle}</span> &middot; {element.companyName} &middot; {element.location} &middot; {element.dateSpan} &middot;&nbsp;
                <a
                    onClick={(event) => {
                        this._toggle(event, element);
                    }}>{this.state[element.stateKey] ? 'Hide' : 'Read More'}</a>
            </p>, this.state[element.stateKey] ? element.element : ''];
        });

        return (
            <article id="experience">
                <h1>Experience</h1>
                {elementsDisplay}
            </article>
        );
    }

    _toggle(event, elementData) {
        const scrollToNode = event.target.parentNode;
        const stateKey = elementData.stateKey;
        const companyName = elementData.companyName;
        this.setState((prevState) => ({ [stateKey]: !prevState[stateKey] }), () => {
            if (this.state[stateKey]) {
                smoothScroll.animateScroll(scrollToNode, false, {
                    after: () => {
                        window.ga('send', 'event', 'Experience', companyName, 'Toggle', this.state[stateKey]);
                    },
                });
            }
        });
    }
}

export default Experience;