import React from 'react';
import Header from '../components/Header';
import Overview from '../components/Overview';
import Experience from '../components/Experience/Experience';
import Education from '../components/Education/Education';
import Achievements from '../components/Achievements/Achievements';
import Contact from '../components/Contact/Contact';

class Resume extends React.Component {
    componentDidMount() {

        this.initialScrollTrack = () => {
            window.removeEventListener('scroll', this.initialScrollTrack, false);
            window.ga('send', 'event', 'Resume', 'Initial Scroll was Manual');
        };
        window.addEventListener('scroll', this.initialScrollTrack, false);
    }

    render() {
        return (
            <div className="container">
                <Header parent={this}/>
                <Overview/>
                <div className="resume-content">
                    <Experience/>
                    <div className="black-rectangle spacer"/>
                    <Education/>
                    <div className="spacer black-rectangle"/>
                    <Achievements/>
                    <div className="spacer black-rectangle"/>
                    <Contact/>
                    <div className="spacer black-rectangle"/>
                </div>
            </div>
        );
    }
}

export default Resume;