import React from 'react';
import SmoothScroll from 'smooth-scroll';
const smoothScroll = new SmoothScroll();

class Header extends React.Component {

    scrollToOverview() {
        window.removeEventListener('scroll', this.props.parent.initialScrollTrack, false);
        smoothScroll.animateScroll(document.querySelector('.overview'), false, {
            after: () => {
                window.ga('send', 'event', 'Header', 'Scroll to overview');
            },
            easing: 'easeInOutCubic',
            speed: 750
        });
    }

    render() {
        return (
            <header className="header"
                    style={{
                        height: "100vh"
                    }}>
                <div className="content">
                    <div className="profile-pic"/>
                    <h1>Ben Sisson</h1>
                    <h2>Software Engineer &middot; Nerd</h2>
                </div>

                <div
                    className="show-more-chevron"
                    onClick={this.scrollToOverview.bind(this)}/>
            </header>
        );
    }
}

export default Header;