import React from 'react';
class Achievements extends React.Component {
    trackOutbound(event) {
        window.ga('send', 'event', 'Achievements', 'Link', 'OutboundLink', event.target.href);
    }
    trackInternal(event) {
        window.ga('send', 'event', 'Achievements', 'Link', 'InternalLink', event.target.href);
    }
    render() {
        return (
            <article id="achievements">
                <h1>Achievements</h1>
                <p><span>Published White Paper</span> &middot; An Adaptive Replication Algorithm in P2P File
                    Systems &middot; 2009  &middot; <a
                        onClick={this.trackInternal.bind(this)}
                        target="_blank"
                        href="/media/An_Adaptive_Replication_Algorithm_in_P2P_File_Syst.pdf">Read More</a></p>

                <p><span>Award &ldquo;Best Internal Tool&rdquo;</span> &middot; Disney Hack Days &middot; 2016 &middot; <a
                    onClick={this.trackOutbound.bind(this)}
                    target="_blank"
                    href="https://www.youtube.com/watch?v=lMuQ_L18m6o">Watch</a>
                </p>
            </article>
        );
    }
}

export default Achievements;